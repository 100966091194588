"use client";
import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import logo from "../public/assets/images/logo.webp";
import Image from "next/image";
import Link from "next/link";
import { handleOpenChat } from "@/config";

const Navigation = () => {
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Link className="nav-brand" href="/">
            <Image src={logo} alt="logo" style={{ height: "auto" }} />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link className="nav-link" href="/">
                Home
              </Link>
              <Link className="nav-link" href="/about">
                About
              </Link>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <Link
                  className="dropdown-item"
                  href="/write-my-cipd-assignments"
                >
                  CIPD Assignment Help
                </Link>
                <Link
                  className="dropdown-item"
                  href="/mba-project-assignment-help"
                >
                  MBA Project Help
                </Link>
                <Link className="dropdown-item" href="/dissertation-help">
                  Dissertation Help
                </Link>
                <Link className="dropdown-item" href="/essay-writing-service">
                  Essay Writing
                </Link>
                <Link className="dropdown-item" href="/university-assignments">
                  University Assignment
                </Link>
                <Link className="dropdown-item" href="/nursing">
                  Nursing Help
                </Link>
                <Link
                  className="dropdown-item"
                  href="/dissertation-editing-proofreading"
                >
                  Dissertation Editing
                </Link>
                <Link className="dropdown-item" href="/personal-statement">
                  Personal Statement
                </Link>
              </NavDropdown>
              <Link className="nav-link" href="/pricing">
                Pricing
              </Link>
              <Link className="nav-link" href="/student-review">
                Reviews
              </Link>
              <Link className="nav-link" href="/blog/">
                Blog
              </Link>
              <Link className="nav-link ordernow" href="/order">
                Order Now
              </Link>
              <Link
                className="nav-link livechat"
                href="#"
                onClick={handleOpenChat}
              >
                Live Chat
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
