export const whatsapplink = "https://wa.me/97142473777";
export const emaillink = "mailto:info@assignmentwritingservice.ae";
export const livechat = "";
export const API_URL = "https://assignmentwritingservice.ae/api/";
export const handleOpenChat = () => {
  if (typeof Tawk_API !== "undefined") {
    // Open Tawk.to chat
    Tawk_API.toggle();
  }
};
