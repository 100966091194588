"use client";
import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, [scroll]);
  return (
    <>
      <div className={`header_Sec ${scroll ? "active" : ""}`}>
        <Navigation />
      </div>
    </>
  );
};

export default Header;
