"use client";
import Image from "next/image";
import logo from "../public/assets/images/logo.webp";
import Whatsappicon from "../public/assets/images/Mainwhatssapp.webp";
import msgicon from "../public/assets/images/msgicon.png";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { emaillink, whatsapplink } from "@/config";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(new Date().getFullYear());
  }, [date]);

  return (
    <>
      <div className="footer_Sec">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <Link href="/">
                <Image src={logo} style={{ height: "auto" }} alt="logo" />
              </Link>
            </Col>

            <Col lg={6} md={6}>
              <ul className="socialFooter">
                <li>
                  <Link href="https://www.facebook.com/assignmentwiritngserviceUAE/">
                    <FaFacebook />
                  </Link>
                </li>

                <li>
                  <Link href="https://twitter.com/UAE_Assignment">
                    <FaXTwitter />
                  </Link>
                </li>

                <li>
                  <Link href="https://www.instagram.com/assignmentwritingserviceuae/">
                    <FaInstagram />
                  </Link>
                </li>

                <li>
                  <Link href="https://www.linkedin.com/company/assignment-writing-service-uae/">
                    <FaLinkedinIn />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={4}>
              <div className="footerInfo">
                <b>Disclaimer</b>

                <p>
                  We delivers the best academic help. The work produced is in
                  the form of an assistant which is delivery solely for
                  guidance. It does not offer the work to students to be
                  presented as their own. The work produced is explicitly
                  dedicated to guidance purpose only.
                </p>
              </div>
            </Col>

            <Col lg={5} md={5}>
              <div className="footerInfo MySer">
                <b>Quick Links</b>

                <ul>
                  <li>
                    <Link href="/">Home</Link>
                  </li>

                  <li>
                    <Link href="/about">About Us</Link>
                  </li>

                  <li>
                    <Link href="/pricing">Price plan</Link>
                  </li>

                  <li>
                    <Link href="/student-review">Reviews</Link>
                  </li>

                  <li>
                    <Link href="/blog/">Blogs</Link>
                  </li>

                  <li>
                    <Link href="/order">Order Now</Link>
                  </li>
                </ul>
              </div>

              <div className="footerInfo MySer">
                <b>Services Links</b>

                <ul>
                  <li>
                    <Link href="/mba-project-assignment-help">
                      MBA Project Help
                    </Link>
                  </li>

                  <li>
                    <Link href="/essay-writing-service">Essay Writing</Link>
                  </li>

                  <li>
                    <Link href="/write-my-cipd-assignments">
                      Cipd Assignment Help
                    </Link>
                  </li>

                  <li>
                    <Link href="/university-assignments">
                      University Assignment
                    </Link>
                  </li>

                  <li>
                    <Link href="/dissertation-help">Dissertation Help</Link>
                  </li>

                  <li>
                    <Link href="/nursing">Nursing Help</Link>
                  </li>

                  <li>
                    <Link href="/dissertation-editing-proofreading">
                      Dissertation Editing
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={3} md={3}>
              <div className="footerInfo">
                <b>Company</b>

                <div className="emailFot">
                  <span>Email</span>

                  <Link href={emaillink}>info@assignmentwritingservice.ae</Link>
                </div>

                <div className="emailFot">
                  <span>Phone No</span>
                  <Link href={whatsapplink}>+971 42473777</Link>
                </div>

                <div className="emailFot">
                  <span>Address</span>
                  <p>13th St - Oud Metha - Dubai - United Arab Emirates</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="copyrightsec">
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <div className="footerInfo">
                  <ul className="policydata">
                    <li>
                      <Link href="/privacy-policy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link href="/revision-policy">Revision Policy</Link>
                    </li>
                    <li>
                      <Link href="/refund-policy">Refund Policy</Link>
                    </li>
                    <li>
                      <Link href="/terms-and-conditions">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="copyrightxx">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <p className="m-0 p-0 text-center">
                @copyright {date} All Rights Reserved (Assignment Writing
                Service)
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="fixedbtnwhatsapp">
        <ul className="HideData">
          <li>
            <Link href="https://wa.me/97142473777">
              <Image
                src={Whatsappicon}
                style={{ height: "auto" }}
                alt="Whatsappicon"
              />
            </Link>
          </li>

          <li>
            <Link href="https://m.me/assignmentwiritngserviceUAE/">
              <Image src={msgicon} style={{ height: "auto" }} alt="msgicon" />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
