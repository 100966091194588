"use client";
import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const Tawktomessenger = () => {
  return (
    <>
      <TawkMessengerReact
        propertyId="5c348a88361b3372892efb91"
        widgetId="default"
      />
    </>
  );
};

export default Tawktomessenger;
